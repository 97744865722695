<template>
    <div>
        <div id="user-form">
            <FormErrors
                :errors="errorList"
                @dismiss-errors="onDismissErrors"
            />

            <form
                ref="formRef"
                novalidate
                @submit.prevent="onSubmit"
            >
                <BaseFormField
                    :required="true"
                    :errors="[errors.firstName]"
                >
                    <template #label>
                        <label for="firstName">Voornaam</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="firstName"
                            v-model="firstName"
                            type="text"
                            placeholder="Vul je voornaam in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    :errors="[errors.preposition]"
                >
                    <template #label>
                        <label for="preposition">Tussenvoegsel</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="preposition"
                            v-model="preposition"
                            type="text"
                            placeholder=""
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    :required="true"
                    :errors="[errors.lastName]"
                >
                    <template #label>
                        <label for="lastName">Achternaam</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="lastName"
                            v-model="lastName"
                            type="text"
                            placeholder="Vul je achternaam in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    :errors="[errors.birthDate]"
                >
                    <template #label>
                        <label for="birthDate">Geboortedatum</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="birthDate"
                            v-model="birthDate"
                            type="date"
                            placeholder="Vul je geboortedatum in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    :errors="[errors.gender]"
                >
                    <template #label>
                        <label for="gender">Geslacht</label>
                    </template>

                    <template #default>
                        <BaseSelect
                            id="gender"
                            v-model="gender"
                            :options="genders"
                            deselect-sr-label="Deselecteer geslacht"
                            selected-sr-label="Geselecteerd geslacht"
                            toggle-sr-label="Selecteer een geslacht"
                        />
                    </template>
                </BaseFormField>

                <!-- <BaseFormField
                    :errors="[errors.functionName]"
                >
                    <template #label>
                        <label for="functionName">Functie</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="functionName"
                            v-model="functionName"
                            type="text"
                            placeholder="Vul je functie in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    :errors="[errors.phoneLandline]"
                >
                    <template #label>
                        <label for="phoneLandline">Vaste telefoon</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="phoneLandline"
                            v-model="phoneLandline"
                            type="text"
                            placeholder="Vul je vaste telefoonnummer in"
                        />
                    </template>
                </BaseFormField>

                <BaseFormField
                    :errors="[errors.phoneMobile]"
                >
                    <template #label>
                        <label for="phoneMobile">Mobiele telefoon</label>
                    </template>

                    <template #default>
                        <BaseInput
                            id="phoneMobile"
                            v-model="phoneMobile"
                            type="text"
                            placeholder="Vul je mobiele telefoonnnummer in"
                        />
                    </template>
                </BaseFormField> -->

                <BaseFormField>
                    <template #default>
                        <div class="form__actions">
                            <BaseButton
                                type="button"
                                class="base-button--transparent-dark"
                                size="medium"
                                @click="$emit('dismiss')"
                            >
                                Annuleer
                            </BaseButton>

                            <BaseButton
                                type="submit"
                                size="medium"
                                :is-loading="isLoading"
                            >
                                Wijzigingen opslaan
                            </BaseButton>
                        </div>
                    </template>
                </BaseFormField>
            </form>
        </div>
    </div>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useAuthStore } from '~/store/auth';
import { useGtm } from '@gtm-support/vue-gtm';

const props = defineProps({
    successRedirectPath: {
        type: String,
        default: ''
    },
});

const { successRedirectPath } = toRefs(props);

const emit = defineEmits(['dismiss']);

const authStore = useAuthStore();

const user = computed(() => {
    return authStore.userModel;
});

const globalErrors = {
    fallback: 'Er is iets misgegaan bij het wijzigen. Probeer het later nog eens.',
};

// Set validation schema
const validationSchema = object({
    firstName: string().required('Je voornaam is verplicht'),
    lastName: string().required('Je achternaam is verplicht'),
    preposition: string(),
    birthDate: string(),
    gender: string(),
    // functionName: string(),
    // phoneLandline: string().length(10, 'Je telefoonnummer moet 10 cijfers lang zijn'),
    // phoneMobile: string().length(10, 'Je telefoonnummer moet 10 cijfers lang zijn'),
});

const { errors, defineField, handleSubmit } = useForm({
    validationSchema,
    initialValues: {
        // functionId: user.value?.getPrimaryFunction()?.id || '',
        // The BaseSelect component requires an empty string as default value, also before the user data has been loaded
        gender: ''
    }
});

// Define models
const [firstName] = defineField('firstName');
const [lastName] = defineField('lastName');
const [preposition] = defineField('preposition');
const [birthDate] = defineField('birthDate');
const [gender] = defineField('gender');
const [functionName] = defineField('functionName');
const [phoneLandline] = defineField('phoneLandline');
const [phoneMobile] = defineField('phoneMobile');

onMounted(() => {
    setInitialValues();
});

// Generic error list
const errorList = ref([]);
const isLoading = ref(false);

const onSubmit = handleSubmit(values => {
    submitDataToApi(values);
});

const submitDataToApi = async function(formData) {
    errorList.value = [];
    isLoading.value = true;

    try {
        await useLaravelApi(
            `/user/${authStore.windexId}`,
            {
                method: 'PUT',
                data: {
                    ...formData
                },
                accessToken: authStore.accessToken
            }
        ).then(() => {
            useGtm()?.trackEvent({
                event: 'form_submit_custom',
                formHandle: 'userMutationForm'
            });

            if (successRedirectPath.value) {
                emit('dismiss');
                return navigateTo(successRedirectPath.value);
            }
        }).catch(error => {
            setErrorMessage(error.response?.data?.code);
        });

        isLoading.value = false;
    } catch (error) {
        setErrorMessage();
        isLoading.value = false;
    }
};

function setErrorMessage(errorCode) {
    const errorMessage = globalErrors[errorCode] ? globalErrors[errorCode] : globalErrors.fallback;
    errorList.value.push(errorMessage);
}

function onDismissErrors() {
    errorList.value = [];
}

const setInitialValues = () => {
    if (!user.value) {
        return;
    }

    firstName.value = user.value.firstName || '';
    lastName.value = user.value.lastName || '';
    preposition.value = user.value.preposition || '';
    birthDate.value = setDate(user.value.birthDate) || '';
    gender.value = user.value.gender || '';

    const getPrimaryFunction = user.value.getPrimaryFunction();
    if (getPrimaryFunction) {
        functionName.value = getPrimaryFunction.function || '';
        phoneLandline.value = getPrimaryFunction.phoneLandline || '';
        phoneMobile.value = getPrimaryFunction.phoneMobile || '';
    }
};

// Set dd-mm-yyyy to yyyy-mm-dd
const setDate = (date) => {
    if (!date) {
        return;
    }

    const year = date.substring(6, 10);
    const month = date.substring(3, 5);
    const day = date.substring(0, 2);

    return `${year}-${month}-${day}`;
};

const genders = [
    {label: 'Selecteer een geslacht', value: ''},
    {label: 'Man', value: 'Man'},
    {label: 'Vrouw', value: 'Vrouw'},
    {label: 'Overig', value: 'Overig'}
];
</script>

<style lang="less" src="./UserMutationForm.less" />
